// To parse this data:
//
//   import { Convert, TypesenseSearchable } from "./file";
//
//   const typesenseSearchable = Convert.toTypesenseSearchable(json);
//
// These functions will throw an error if the JSON doesn't
// match the expected interface, even if the JSON is valid.

import axios from "axios";
import {plainToClass} from "class-transformer";

export class TypesenseSearchable {
  facet_counts:   any[];
  found:          number;
  hits:           SearchableHit[];
  out_of:         number;
  page:           number;
  request_params: RequestParams;
  search_cutoff:  boolean;
  search_time_ms: number;

  constructor(facet_counts: any[], found: number, hits: SearchableHit[], out_of: number, page: number, request_params: RequestParams, search_cutoff: boolean, search_time_ms: number) {
    this.facet_counts = facet_counts;
    this.found = found;
    this.hits = hits;
    this.out_of = out_of;
    this.page = page;
    this.request_params = request_params;
    this.search_cutoff = search_cutoff;
    this.search_time_ms = search_time_ms;
  }

  static async get(
    query: string = "*",
  ): Promise<TypesenseSearchable | null> {
    const axiosClient = axios.create({
      headers: {
        'x-typesense-api-key': window.typesenseSearchApiKey,
      },
    })
    let url = this.createTypesenseURL(query)

    try {
      let response = await axiosClient(url)
      let jsonObject = response.data as Object;
      return plainToClass(TypesenseSearchable, jsonObject)
    } catch (e) {
      return null
    }
  }

  static createTypesenseURL(
    query: string = "*"
  ): string {
    let urlBase = 'https://' + window.typesenseUrl + "/collections/searchable/documents/search"
    const url = new URL(urlBase);

    url.searchParams.append("q", query);
    url.searchParams.append("per_page", "100");
    url.searchParams.append("query_by", "name,short_address")
    return url.href
  }}

export interface SearchableHit {
  document:   SearchableDocument;
  highlights: Highlight[];
  text_match: number;
}

export class SearchableDocument {
  id:                string;
  impressions_count: number;
  location:          number[];
  name:              string;
  region_name:       string;
  short_address:     string;
  thumbnail_url:     string;
  type:              string;
  url:               string;

  constructor(
    id: string,
    impressions_count: number,
    location: number[],
    name: string,
    region_name: string,
    short_address: string,
    thumbnail_url: string,
    type: string,
    url: string
  ) {
    this.id = id;
    this.impressions_count = impressions_count;
    this.location = location;
    this.name = name;
    this.region_name = region_name;
    this.short_address = short_address;
    this.thumbnail_url = thumbnail_url;
    this.type = type;
    this.url = url;
  }

  getItemId(): string {
    return this.id.split(this.type + "_")[1]
  }

  getSearchableType(): SearchableType {
    return this.type as SearchableType
  }



}

export enum SearchableType {
  Trail = "Trail",
  Country = "Country",
  Region = "Region",
  Park = "Park"
}

export interface Highlight {
  field:          string;
  matched_tokens: string[];
  snippet:        string;
}

export interface RequestParams {
  collection_name: string;
  per_page:        number;
  q:               string;
}

// Converts JSON strings to/from your types
// and asserts the results of JSON.parse at runtime
export class Convert {
  public static toTypesenseSearchable(json: string): TypesenseSearchable {
    return cast(JSON.parse(json), r("TypesenseSearchable"));
  }

  public static typesenseSearchableToJson(value: TypesenseSearchable): string {
    return JSON.stringify(uncast(value, r("TypesenseSearchable")), null, 2);
  }
}

function invalidValue(typ: any, val: any, key: any = ''): never {
  if (key) {
    throw Error(`Invalid value for key "${key}". Expected type ${JSON.stringify(typ)} but got ${JSON.stringify(val)}`);
  }
  throw Error(`Invalid value ${JSON.stringify(val)} for type ${JSON.stringify(typ)}`, );
}

function jsonToJSProps(typ: any): any {
  if (typ.jsonToJS === undefined) {
    const map: any = {};
    typ.props.forEach((p: any) => map[p.json] = { key: p.js, typ: p.typ });
    typ.jsonToJS = map;
  }
  return typ.jsonToJS;
}

function jsToJSONProps(typ: any): any {
  if (typ.jsToJSON === undefined) {
    const map: any = {};
    typ.props.forEach((p: any) => map[p.js] = { key: p.json, typ: p.typ });
    typ.jsToJSON = map;
  }
  return typ.jsToJSON;
}

function transform(val: any, typ: any, getProps: any, key: any = ''): any {
  function transformPrimitive(typ: string, val: any): any {
    if (typeof typ === typeof val) return val;
    return invalidValue(typ, val, key);
  }

  function transformUnion(typs: any[], val: any): any {
    // val must validate against one typ in typs
    const l = typs.length;
    for (let i = 0; i < l; i++) {
      const typ = typs[i];
      try {
        return transform(val, typ, getProps);
      } catch (_) {}
    }
    return invalidValue(typs, val);
  }

  function transformEnum(cases: string[], val: any): any {
    if (cases.indexOf(val) !== -1) return val;
    return invalidValue(cases, val);
  }

  function transformArray(typ: any, val: any): any {
    // val must be an array with no invalid elements
    if (!Array.isArray(val)) return invalidValue("array", val);
    return val.map(el => transform(el, typ, getProps));
  }

  function transformDate(val: any): any {
    if (val === null) {
      return null;
    }
    const d = new Date(val);
    if (isNaN(d.valueOf())) {
      return invalidValue("Date", val);
    }
    return d;
  }

  function transformObject(props: { [k: string]: any }, additional: any, val: any): any {
    if (val === null || typeof val !== "object" || Array.isArray(val)) {
      return invalidValue("object", val);
    }
    const result: any = {};
    Object.getOwnPropertyNames(props).forEach(key => {
      const prop = props[key];
      const v = Object.prototype.hasOwnProperty.call(val, key) ? val[key] : undefined;
      result[prop.key] = transform(v, prop.typ, getProps, prop.key);
    });
    Object.getOwnPropertyNames(val).forEach(key => {
      if (!Object.prototype.hasOwnProperty.call(props, key)) {
        result[key] = transform(val[key], additional, getProps, key);
      }
    });
    return result;
  }

  if (typ === "any") return val;
  if (typ === null) {
    if (val === null) return val;
    return invalidValue(typ, val);
  }
  if (typ === false) return invalidValue(typ, val);
  while (typeof typ === "object" && typ.ref !== undefined) {
    typ = typeMap[typ.ref];
  }
  if (Array.isArray(typ)) return transformEnum(typ, val);
  if (typeof typ === "object") {
    return typ.hasOwnProperty("unionMembers") ? transformUnion(typ.unionMembers, val)
      : typ.hasOwnProperty("arrayItems")    ? transformArray(typ.arrayItems, val)
        : typ.hasOwnProperty("props")         ? transformObject(getProps(typ), typ.additional, val)
          : invalidValue(typ, val);
  }
  // Numbers can be parsed by Date but shouldn't be.
  if (typ === Date && typeof val !== "number") return transformDate(val);
  return transformPrimitive(typ, val);
}

function cast<T>(val: any, typ: any): T {
  return transform(val, typ, jsonToJSProps);
}

function uncast<T>(val: T, typ: any): any {
  return transform(val, typ, jsToJSONProps);
}

function a(typ: any) {
  return { arrayItems: typ };
}

function u(...typs: any[]) {
  return { unionMembers: typs };
}

function o(props: any[], additional: any) {
  return { props, additional };
}

function m(additional: any) {
  return { props: [], additional };
}

function r(name: string) {
  return { ref: name };
}

const typeMap: any = {
  "TypesenseSearchable": o([
    { json: "facet_counts", js: "facet_counts", typ: a("any") },
    { json: "found", js: "found", typ: 0 },
    { json: "hits", js: "hits", typ: a(r("Hit")) },
    { json: "out_of", js: "out_of", typ: 0 },
    { json: "page", js: "page", typ: 0 },
    { json: "request_params", js: "request_params", typ: r("RequestParams") },
    { json: "search_cutoff", js: "search_cutoff", typ: true },
    { json: "search_time_ms", js: "search_time_ms", typ: 0 },
  ], false),
  "Hit": o([
    { json: "document", js: "document", typ: r("Document") },
    { json: "highlights", js: "highlights", typ: a(r("Highlight")) },
    { json: "text_match", js: "text_match", typ: 0 },
  ], false),
  "Document": o([
    { json: "id", js: "id", typ: "" },
    { json: "impressions_count", js: "impressions_count", typ: 0 },
    { json: "location", js: "location", typ: a(3.14) },
    { json: "name", js: "name", typ: "" },
    { json: "region_name", js: "region_name", typ: "" },
    { json: "short_address", js: "short_address", typ: "" },
    { json: "thumbnail_url", js: "thumbnail_url", typ: "" },
    { json: "type", js: "type", typ: "" },
    { json: "url", js: "url", typ: "" },
  ], false),
  "Highlight": o([
    { json: "field", js: "field", typ: "" },
    { json: "matched_tokens", js: "matched_tokens", typ: a("") },
    { json: "snippet", js: "snippet", typ: "" },
  ], false),
  "RequestParams": o([
    { json: "collection_name", js: "collection_name", typ: "" },
    { json: "per_page", js: "per_page", typ: 0 },
    { json: "q", js: "q", typ: "" },
  ], false),
};
