import "./application";
import './js/svg';
import './js/download_app_prompt';
import './feedback/main';
import './css/overrides/home.scss';
import './home_search/main.ts';

let searchModal = document.getElementById('searchModal');
searchModal.addEventListener('shown.bs.modal', function () {
  document.getElementById('home-search').focus();
});

console.log('Hello from home.js!');
